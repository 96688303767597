import styled from "styled-components";

let offWhite = "#d7d3c4";
let offBlack = "#030b24";
let lsGreen = "#415248";
let lsLightBrown = "#ac9c7b";

export const StyledMobileContactUs = styled.div `

    display: flex;
    flex-direction: column;
    background-color: ${offWhite};

    ${'' /* position: absolute;
    top: 0; */}

    .pageHead {
        background-image: url(images/ls/IMG_2576.JPG);
        background-color: rgba(0,104,178,.4);
        background-blend-mode: multiply;
        background-size: cover;
        background-position: 0% 19%;
        padding: 0 0 5% 0;
    }

    .pageHead h1 {
        ${'' /* positional styling */}
        width: 70%;
        margin: 0 auto;
        margin-top: 20%;
        margin-bottom: 30%;
        text-align: center;

        ${'' /* border box styling */}
        text-align: center;

        ${'' /* text styling */}
        line-height: 1.25em;
        font-size: 10vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        color: ${offWhite};
    }







    .quoteLink {
        text-decoration: none;
        align-items: center;
        box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
    }

    .quoteCard {
        display: flex;
        background-color: ${lsLightBrown};
        color: ${offBlack};
        justify-content: center;
    }

    .phoneIcon {
        width: 15%;
        padding: 1%;
        padding-top: 2%;
        height: 10vw;
    }

    .quoteCard img {
        width: 13%;
        padding: 2%;
    }

    .quoteCard .callText {
        line-height: 1.25em;
        font-size: 3vw;
        font-weight: 900;
    }




    .pageTextContent {
        background-color: ${lsGreen};
        color: ${offWhite};
    }

    .pageTextContent div {
        margin: 18% 10% 18% 10%;
    }

    .pageTextContent h2 {
        line-height: 1.25em;
        font-size: 7vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .pageTextContent h3 {
        margin-top: 15%;
        line-height: 1.25em;
        font-size: 5vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .pageTextContent p, li {
        text-shadow: 0px 1px 2px rgba(0,0,0,0.4),
        0px 2px 11px rgba(0,0,0,0.1),
        0px 4px 20px rgba(0,0,0,0.1);
        font-weight: 550;
        font-size: 3.5vw;
    }






    .socialMedia {
        flex-direction: column;
        margin: 0 auto;
        font-size: 6vw;
    }

    .socialMedia div {
        display: flex;
        justify-content: center;
    }

    .socialMedia div a {
        margin: 0 4% 0 4%;
        transition: 0.5s;
        color: inherit;
    }

    .socialMedia div a:hover {
        opacity: .9;
        transform: scale(1.05);
    }







    .contactIcons {
        display: flex;
        margin: 5% 10% 5% 10%;
        text-align: center;
        flex-direction: column;
    }

    .contactIcons div {
        text-decoration: none;
        color: inherit;
        border-top: grey 1px solid;
        ${'' /* border-bottom: grey 1px solid; */}
        width: 100%;
        margin: 0% 2% 0% 2%;
        padding: 5% 0% 5% 0%;
        transition: 0.1s;
        font-size: 5vw;
    }

    .contactIcons a {
        text-decoration: none;
        color: inherit;
    }

    .contactIcons div:hover {
        transform: scale(1.10);
    }

    .contactIcons p {
        font-weight: 300;
    }

    









    .contactForm {
        display: flex;
        flex-direction: column;
        background-color: ${lsGreen};
        padding: 5% 10% 5% 10%;
        color: ${offWhite};
        font-size: 3vw;
        font-weight: 700;
    }

    .contactForm form div {
        margin-top: 4%;
    }

    .contactForm input {
        padding: 2% 0 2% 0;
        width: 98%;
        background-color: ${offWhite};
        color: ${lsGreen};
        font-size: 5vw;
    }

    .contactForm textarea {
        margin-top: 2%;
        height: 10vh;
        width: 98%;
        background-color: ${offWhite};
        color: ${lsGreen};
        font-size: 4vw;
    }

    .contactForm .submitButton {
        margin-top: 4%;
        margin-bottom: 2%;
        width: 100%;
    }

    .contactForm textarea {
        resize: none;
    }

    .contactForm h1 {
        line-height: 1.25em;
        font-size: 7vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        text-align: center;
    }











    .mapContainer {
        padding: 5% 5% 5% 5%;
        background-color: ${lsGreen};
    }

    .mapContainer a {
        text-decoration: none;
        font-styling: none;
        margin: 0 auto;
    }

    .mapContainer h3 {
        text-align: center;
        line-height: 1.25em;
        font-size: 7vw;
        font-weight: 700;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        color: ${offWhite};
        transition: 0.3s;
    }

    .mapContainer h3:hover {
        transform: scale(1.05);
    }
`