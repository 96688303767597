import {StyledWhatWeDo} from "./styles/WhatWeDo.styled"
import {StyledMobileWhatWeDo} from "./mobileStyles/MobileWhatWeDo.styled"
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";



function CreateHtml() {

    const [counterOn, setCounterOn] = useState(false);

    return (
        <>
            <section className="pageHead">
                <h1>Services</h1>
            </section>
            <a href="tel:717-733-3660" className="quoteLink"><div className="quoteCard">
            <FontAwesomeIcon className="phoneIcon" icon={faPhone} size="2x"/>
                <div className="callText">
                    <p>GET A FREE QUOTE TODAY</p>
                    <p>717-733-3660</p>
                </div>
            </div></a>

            <section className="pageTextContent">
                <div>
                    <h2>We Specialize In</h2>
                    <p>L & S Truck Painting LLC specializes in keeping our customers’ 
                    vehicles looking as good as (or better than!) the day they rolled off the lot. 
                    We understand how easy it is for your vehicle to get dinged, dented and scratched 
                    in the course of your everyday comings and goings. It’s frustrating, 
                    but when your door gets nicked or your fender gets kicked, you don’t have to get bent out of shape! 
                    Simply call your experts at L & S Truck Painting and 
                    we’ll have your vehicle looking shiny and smooth again in no time!</p>
                    <h3>L&S Truck Painting, LLC. proudly offers the following services:</h3>
                    <ul>
                        <li>Free consultations</li>
                        <li>Free estimates</li>
                        <li>Overall painting from start to finish</li>
                        <li>Quality work</li>
                        <li>Quick turnaround</li>
                        <li>Light and Heavy duty collision repair</li>
                        <li>Repainting</li>
                    </ul>
                </div>
            </section>
            <section className="servicesListContent">
                <div className="containerDiv">
                    <h2>Our Paint System</h2>
                    <div className="wrapItems" id="red"></div>
                    <div id="mixingRooms">
                    <img src="mixingRoom1.jpg" alt="Paint Mixing Room"></img>
                    <div>
                        <p>We are certified Sikkens and Dupont shop. We have two mixing machines to customize your colors, and color match existing paint with our paint cameras.</p>
                    </div> 
                    <img src="mixingRoom3.jpg" alt="Paint Mixing Room"></img>
                    </div>
                    <div id="logos">
                        <img src="sikkensBT.PNG" alt="Sikkens Paint Logo"></img>
                        <img src="emron.jpg" alt="Emron Paint Logo"></img>
                    </div>
                </div>
            </section>
            <section className="servicesListContent">
                <div className="containerDiv">
                    <h2>What We Do</h2>
                    <div className="wrapItems" id="red">
                        <img src="hood.jpg" alt="Sikkens Color Wheel"></img>
                        <div className="wrapText">
                            <h3>Custom Truck Painting</h3>
                            <p>Before a repair starts, we remove all old graphics and diligently prepare 
                            all surfaces for the cleanest look possible. Additionally, we can paint stripes for a custom look. 
                            If you plan to have new graphics applied, we can coordinate with the shop of your choice after the paint job is complete. 
                            Our painters strive for excellence in every step; 
                            from accurately matching your paint to using high quality tools to ensure a clean finish. 
                            Custom paint colors are also available upon request.</p>
                        </div> 
                        <div>
                </div>
                    </div>
                    <div className="wrapItems">
                        <img src="images/whatWeDo/lightCollision.png" alt="Sikkens Color Wheel"></img>
                        <div className="wrapText">
                            <h3>Light Collision</h3>
                            <p>Our experienced technicians are trained to evaluate and determine if, after a collision, 
                            your existing parts can be repaired back to like new standards. 
                            If not, we will replace damaged parts with OEM parts, or if you choose, 
                            with parts from our trusted aftermarket source. Either way, you can expect good results.</p>
                        </div> 
                    </div>
                    <div className="wrapItems" id="red">
                        <img src="jordan.jpg" alt="Sikkens Color Wheel"></img>
                        <div className="wrapText">
                            <h3>Repair</h3>
                            <p>We believe in conscientious repair. 
                            This means we strive for the highest quality repairs possible. 
                            Well thought out repair procedures and the proper materials ensure long-lasting results.</p>
                        </div> 
                    </div>
                    <div className="wrapItems">
                        <img src="painter.jpg" alt="Sikkens Color Wheel"></img>
                        <div className="wrapText">
                            <h3>Repainting</h3>
                            <p>Occasionally, paint deteriorates from the abuse of the elements 
                            or simply from the day-to-day wear and tear of the job. 
                            Unsound paint is thoroughly removed and the surface is painstakingly prepared 
                            to produce a solid foundation for new, durable paint coverage. 
                            We’re sure the durable finish and long-lasting shine of our paint will leave you smiling.</p>
                        </div> 
                    </div>
                    <div className="wrapItems" id="red">
                        <img src="truck.jpg" alt="Sikkens Color Wheel"></img>
                        <div className="wrapText">
                            <h3>Rigs</h3>
                            <p>Old or new, bring your rig to us! We will work on older trucks as well as the new ones. 
                            We also will repair and/or repaint all makes and models of trucks. 
                            In need of fire equipment repair? No problem! We will repair and paint fire equipment as well. </p>
                        </div> 
                    </div>
                </div>
            </section>

            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                <section className="getFreeQuote">
                    <h2>Get a Free Quote</h2>
                    <Link className="contactUsButton" to="/contact-us">Contact Us</Link>
                    <p>or give us a call at <a className="phone" href="tel:717-733-3660">717-733-3660</a></p>
                </section>
            
                <section className="experienceData">
                    <div>
                        <h3>{counterOn && <CountUp end={2000}/>}+</h3>
                        <p>Hours</p>
                    </div>
                    <div>
                        {counterOn &&<h3><CountUp end={15}/></h3>}
                        <p>Years Experience</p>
                    </div>
                    <div>
                        {counterOn &&<h3><CountUp end={950}/>+</h3>}
                        <p>Happy Clients</p>
                    </div>
                    <div>
                        {counterOn &&<h3><CountUp end={6}/></h3>}
                        <p>Employees</p>
                    </div>
                </section>
            </ScrollTrigger>
        </>
    )
}

const WhatWeDo = ({isMobile}) => {
    if (!isMobile) {
        return(
          <StyledWhatWeDo>
            {CreateHtml()}
          </StyledWhatWeDo>
        )
      } else {
        return(
          <StyledMobileWhatWeDo>
            {CreateHtml()}
          </StyledMobileWhatWeDo>
        )
      }
}

export default WhatWeDo;