import { useEffect, useState } from "react"
import { useLocation } from 'react-router-dom';

export function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      // "document.documentElement.scrollTo" is the magic for React Router Dom v6
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant", // Optional if you want to skip the scrolling animation
      });
    }, [pathname]);
  
    return null;
  }
  

export function useWindowWidth() {
    const [size, setSize] = useState([window.innerWidth]);
    useEffect(() => {
    const handleResize = () => {
        setSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
        window.removeEventListener("resize", handleResize);
    }
    }, []);
    return size;
}

export function WhichPageAmIOn() {
    let location = useLocation();
    return location.pathname;
}
