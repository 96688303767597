import { StyledJobOpportunities } from "./styles/JobOpportunities.styled"
import { StyledMobileJobOpportunities } from "./mobileStyles/MobileJobOpportunities.styled"
import { Link } from 'react-router-dom'

function CreateHtml() {
    return (
        <>
            <section className="pageHead">
                <h1>Current Job Opportunities</h1>
            </section>

            <section className="pageTextContent">
              <div>
                <h2>Full time body technician</h2>
                <p>L&S is expanding and looking for a technician to help repair our customers trucks.</p>
                <p>The opening would require disassembly, reassembly and light body work. Sanding and prepping parts for paint would also be involved.</p>
                <p>We are a quality body shop that is built around our great reputation and enjoyable atmosphere, the candidate we hire will have a good attitude and be a team player.</p>
                <h3>*No experience needed, but preferred</h3>
              </div>
            </section>

            <section className="applyNow">
              <h2>Apply Today</h2>
              <Link className="contactUsButton" to="/apply">APPLY NOW</Link>
              <p>or email your resume to <a className="email" href="mailto:lynn@landstruckpainting.net">lynn@landstruckpainting.net</a></p>
            </section>
        </>
    )
}

const JobOpportunities = ({isMobile}) => {
    if (!isMobile) {
        return(
          <StyledJobOpportunities>
            {CreateHtml()}
          </StyledJobOpportunities>
        )
      } else {
        return(
          <StyledMobileJobOpportunities>
            {CreateHtml()}
          </StyledMobileJobOpportunities>
        )
      }
}

export default JobOpportunities;
















































// Why u lookin down here ???  (͠≖ ͜ʖ͠≖)


// (◕‿◕)╭∩╮


// ( ͡° ͜ʖ ͡°)            ¯\_( ͡° ͜ʖ ͡°)_/¯ 

// (╬ಠ益ಠ)


//              The Council
//        ( ͡°( ͡° ͜ʖ( ͡° ͜ʖ ͡°)ʖ ͡°) ͡°)





















// aint shit down here
// Why'd you check again when you knew?
//(▰˘︹˘ ▰)