import { StyledWhoWeAre } from "./styles/WhoWeAre.styled"
import {StyledMobileWhoWeAre} from "./mobileStyles/MobileWhoWeAre.styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

function CreateHtml() {
    return (
        <>
            <section className="pageHead">
                <h1>Who We Are</h1>
            </section>
            <a href="tel:717-733-3660" className="quoteLink"><div className="quoteCard">
            <FontAwesomeIcon className="phoneIcon" icon={faPhone} size="2x"/>
                <div className="callText">
                    <p>GET A FREE QUOTE TODAY</p>
                    <p>717-733-3660</p>
                </div>
            </div></a>

            <section className="pageTextContent">
                <div>
                    <h2>About Us</h2>
                    <p>L & S Truck Painting LLC was established in 2001. 
                        We perform complete collision repairs and painting – 
                        from the smallest touch-up to most extensive paint job – 
                        for trucks, fire trucks. 
                        We work on owner-operated fleets of any size, whether the 
                        fleet is composed of rigs or trailers.. No job is too big or small. 
                        We service all of Central PA.</p>

                    <p>We use only the best-quality paint with clear-coat 
                        finishes to ensure a long-lasting finish. At L & S Truck Painting LLC, 
                        we go the extra mile for our customers. We will try to get your vehicle 
                        needs taken care of in a timely fashion. Our fast turnaround time will 
                        not compromise the quality of our work. We do quality work at a 
                        reasonable rate. Most insurance accepted.</p>
                </div>
            </section>

            <section className="servicesListContent">
                <div className="containerDiv">
                    <h2>Reviews</h2>
                    <div className="wrapItems">
                    <img src="quotation-mark-right-icon.svg" alt="Left Quote"></img>
                        <div className="wrapText">
                            <p>You guys did my frame on my truck a number of years back and it’s still holding up really well.</p>
                            <p>Tim Keener</p>
                        </div> 
                    </div>
                    <div className="wrapItems">
                    <img src="quotation-mark-right-icon.svg" alt="Left Quote"></img>
                        <div className="wrapText">
                            <p>Glad to see our truck back on the pavement again! Thanks to Lynn for his patience and countless phone cals to make sure the slightest detail wasn’t missed. Safe to say they nailed this one.</p>
                            <p>Dayton Krieder</p>
                        </div> 
                    </div>
                    <div className="wrapItems">
                    <img src="quotation-mark-right-icon.svg" alt="Left Quote"></img>
                        <div className="wrapText">
                            <p>Thanks for the great work on the daycab last month, it get a lot of compliments with the black fender and new grill!</p>
                            <p>Matt Stoltzfus</p>
                        </div> 
                    </div>
                    <div className="wrapItems">
                    <img src="quotation-mark-right-icon.svg" alt="Left Quote"></img>
                        <div className="wrapText">
                            <p>Great shop to get work done at. Lynn and Jordan were very helpful and patiently dealt with the insurance company on a challenging situation!</p>
                            <p>Dale Nolt</p>
                        </div> 
                    </div>
                </div>
            </section>
        </>
    )
}

const WhoWeAre = ({isMobile}) => {
    if (!isMobile) {
        return(
          <StyledWhoWeAre>
            {CreateHtml()}
          </StyledWhoWeAre>
        )
      } else {
        return(
          <StyledMobileWhoWeAre>
            {CreateHtml()}
          </StyledMobileWhoWeAre>
        )
      }
}

export default WhoWeAre;