import { StyledContactUs } from "./styles/ContactUs.styled"
import { StyledMobileContactUs } from "./mobileStyles/MobileContactUs.styled"
import { useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSquarePhone, faMapLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faSquareFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { Map, Marker } from "pigeon-maps"
import emailjs from 'emailjs-com'


function CreateHtml() {

    const d = new Date();
    let day = d.getDay();

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_4wlp7do', 'template_h0tr3fs', form.current, 'L8f_TdEgfuJODLB9p')
          .then((result) => {
              console.log(result.text);
              alert("Thank you, the form was submitted successfully.");
          }, (error) => {
              console.log(error.text);
              alert("Sorry there was an error, please email us directly at lynn@landstruckpainting.net");
          });
          e.target.reset()
    };
    
    return(
        <>
            <section className="pageHead">
                <h1>Contact Us</h1>
            </section>
            <a href="tel:717-733-3660" className="quoteLink"><div className="quoteCard">
            <FontAwesomeIcon className="phoneIcon" icon={faPhone} size="2x"/>
                <div className="callText">
                    <p>GET A FREE QUOTE TODAY</p>
                    <p>717-733-3660</p>
                </div>
            </div></a>

            <section className="pageTextContent">
                <div>
                    <h2>Get in touch</h2>
                    <p>Since 2001, L&S Truck Painting, LLC. has been serving all of central Pennsylvania. 
                    We work on owner-operated fleets of any size including those comprised of rigs, trailers, and light duty trucks. 
                    No job is too big or too small! Most insurances are accepted.</p>
                </div>
                <div>
                <h2>Hours of Operation</h2>
                    <ul>
                        <li className={`${day === 1 ? "bold" : ""}`}>Mon 7:30 AM - 4:30 PM</li>
                        <li className={`${day === 2 ? "bold" : ""}`}>Tue 7:30 AM - 4:30 PM</li>
                        <li className={`${day === 3 ? "bold" : ""}`}>Wed 7:30 AM - 4:30 PM</li>
                        <li className={`${day === 4 ? "bold" : ""}`}>Thu 7:30 AM - 4:30 PM</li>
                        <li className={`${day === 5 ? "bold" : ""}`}>Fri 7:30 AM - 4:30 PM</li>
                        <li className={`${day === 6 ? "bold" : ""}`}>Sat CLOSED</li>
                        <li className={`${day === 7 ? "bold" : ""}`}>Sun CLOSED</li>
                    </ul>
                </div>
            </section>

            <section className="socialMedia">
                <h2>Connect with us</h2>
                <div>
                    <a href="https://www.facebook.com/lstruckpainting01"><FontAwesomeIcon icon={faSquareFacebook} size="3x"/></a>
                    <a href="https://instagram.com/landstruckpainting?igshid=YmMyMTA2M2Y="><FontAwesomeIcon icon={faInstagram} size="3x"/></a>
                </div>
            </section>

            <section className="contactIcons">

                    <a href="mailto:lynn@landstruckpainting.net">
                        <div>
                            <FontAwesomeIcon icon={faEnvelope} size="5x"/>
                            <h3>Email</h3>
                            <p>lynn@landstruckpainting.net</p>
                        </div>    
                    </a>
                
               
                    <a href="http://maps.google.com/?q=305 S Reading Rd, Ephrata, PA 17522">
                        <div>
                            <FontAwesomeIcon icon={faMapLocationDot} size="5x"/>
                            <h3>Address</h3>
                            <p>305 S. Reading Road (Along Route 272) Ephrata, PA 17522</p>
                        </div>    
                    </a>
                
                
                    <a className="phone" href="tel:717-733-3660">
                        <div>
                            <FontAwesomeIcon icon={faSquarePhone} size="5x"/>
                            <h3>Phone</h3>
                            <p>717-733-3660</p>
                        </div>    
                    </a>
                
            </section>

            <section className="contactForm">
                <div>
                    <h1>Get a free quote today</h1>
                </div>
                <form ref={form} onSubmit={sendEmail} method="POST">
                    <input type="hidden" name="email_type" value="Get a quote"/>
                    <div>
                        <label htmlFor="from_name">Name*</label>
                        <input type="text" name="from_name" required="required"/>
                    </div>
                    <div>
                        <label htmlFor="from_email">Email*</label>
                        <input type="email" name="from_email" required="required"/>
                    </div>
                    <div>
                        <label htmlFor="from_phone">Phone Number*</label>
                        <input type="tel" name="from_phone"/>
                    </div>
                    <div>
                        <label htmlFor="message">Comment or Message*</label>
                        <textarea name="message" required="required"/>
                    </div>
                    <input type="submit" value="Submit" className="submitButton"/>
                </form>
            </section>

            <section className="mapContainer">
                <a href="http://maps.google.com/?q=305 S Reading Rd, Ephrata, PA 17522"><h3>305 S. Reading Road (Along Route 272) Ephrata, PA 17522</h3></a>
                <Map height={500} defaultCenter={[40.177380, -76.196360]} defaultZoom={15} maxZoom={15} minZoom={15}>
                    <Marker width={50} anchor={[40.177380, -76.196360]} />
                </Map>
            </section>    
        </>
    )
}

const ContactUs = ({isMobile}) => {
    if (!isMobile) {
        return(
          <StyledContactUs>
            {CreateHtml()}
          </StyledContactUs>
        )
      } else {
        return(
          <StyledMobileContactUs>
            {CreateHtml()}
          </StyledMobileContactUs>
        )
      }
}

export default ContactUs;