import styled from "styled-components";

let lsYellow = "#fff200";
let lsGreen = "#415248";


export const StyledNotFound = styled.section `
    background-color: ${lsGreen};
    text-align: center;
    height:100vh;
    padding-top: 20%;
    font-size: ${props => props.isMobile ? '5vw' : '2vw'};
    color: ${lsYellow};

    p {
        padding-top: 2%;
        margin: 0;
    }
`