import { StyledFooter } from "./styles/Footer.styled"
import { Link } from 'react-router-dom';
import { WhichPageAmIOn } from './Utilities'

const Footer = ({isMobile}) => {
    var currentPage = WhichPageAmIOn();
    return (
        <StyledFooter isMobile={isMobile}>
            <ul>
                <li><Link to="/" className={`${currentPage === "/" ? "highlightNav" : ""}`}>HOME</Link></li>
                <li><Link to="/who-we-are" className={`${currentPage === "/who-we-are" ? "highlightNav" : ""}`}>WHO WE ARE</Link></li>
                <li><Link to="/what-we-do" className={`${currentPage === "/what-we-do" ? "highlightNav" : ""}`}>SERVICES</Link></li>
                <li><Link to="/job-opportunities" className={`${currentPage === "/job-opportunities" ? "highlightNav" : ""}`}>JOB OPPORTUNITIES</Link></li>
                <li><Link to="/gallery" className={`${currentPage === "/gallery" ? "highlightNav" : ""}`}>GALLERY</Link></li>
                <li><Link to="/contact-us" className={`${currentPage === "/contact-us" ? "highlightNav" : ""}`}>CONTACT US</Link></li>
            </ul>
            <p>Copyright © 2022 Truck Painting | Powered by Truck Painting</p>
        </StyledFooter>
    )
}

export default Footer;