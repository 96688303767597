import { StyledHeader } from "./styles/Header.styled"
import { StyledMobileHeader } from "./mobileStyles/MobileHeader.styled"
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { WhichPageAmIOn } from './Utilities'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquareFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

//*** This Method runs after every window resize ***
function CreateHtml(isDesktop, currentPage) {

  // this sets the initial 'show' value via 'displayNav', 
  // but it only updates on refresh hence the conditional below
  let [show, setShow] = useState(isDesktop);


  // if the dom gets switched to desktop (post load), it automatically 'shows' the nav
  if (isDesktop === true && show === false) {
    show = true;
  }

  return(
      <>
        <div>
            <Link to="/" id="imgLink"><img src="images/ls/lslogo.png" alt="L and S truck painting logo" className="lsLogo"></img></Link>
            <div className="socalMediaIconContainer">
              <a href="https://www.facebook.com/lstruckpainting01"><FontAwesomeIcon icon={faSquareFacebook} size="2x"/></a>
              <a href="https://instagram.com/landstruckpainting?igshid=YmMyMTA2M2Y="><FontAwesomeIcon icon={faInstagram} size="2x"/></a>
              <a className="phone" href="tel:717-733-3660">CALL: 717-733-3660</a>
            </div>
            <img className="hamburger" src="hamburger.svg" alt="hamburger icon for mobile nav" onClick={() => setShow(prev => !prev)}></img>
        </div>
        <nav>
        {/* This will only 'show' nav when show == true */}
        {show &&
          <ul>
            <li><Link to="/" onClick={() => setShow(prev => false)} className={`${currentPage === "/" ? "highlightNav" : ""}`}>HOME</Link></li>
            <li><Link to="/who-we-are" onClick={() => setShow(prev => false)} className={`${currentPage === "/who-we-are" ? "highlightNav" : ""}`}>WHO WE ARE</Link></li>
            <li><Link to="/what-we-do" onClick={() => setShow(prev => false)} className={`${currentPage === "/what-we-do" ? "highlightNav" : ""}`}>SERVICES</Link></li>
            <li><Link to="/job-opportunities" onClick={() => setShow(prev => false)} className={`${currentPage === "/job-opportunities" ? "highlightNav" : ""}`}>JOB OPPORTUNITIES</Link></li>
            <li><Link to="/gallery" onClick={() => setShow(prev => false)} className={`${currentPage === "/gallery" ? "highlightNav" : ""}`}>GALLERY</Link></li>
            <li><Link to="/contact-us" onClick={() => setShow(prev => false)} className={`${currentPage === "/contact-us" ? "highlightNav" : ""}`}>CONTACT US</Link></li>
          </ul>
          }
        </nav>
      </>
    )
}

const Header = ({isMobile}) => {
  if (!isMobile) {
    return(
      <StyledHeader>
        {CreateHtml(true, WhichPageAmIOn())}
      </StyledHeader>
    )
  } else {
    return(
      <StyledMobileHeader>
        {CreateHtml(false, WhichPageAmIOn())}
      </StyledMobileHeader>
    )
  }
}

export default Header;