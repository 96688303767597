import { StyledHome } from "./styles/Home.styled"
import { StyledMobileHome } from "./mobileStyles/MobileHome.styled"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSprayCan, faTruckMoving, faMessage, faPhone } from "@fortawesome/free-solid-svg-icons";

function CreateHtml() {
    return (
        <>
            <section className="pageHead">
            </section>
            <a href="tel:717-733-3660" className="quoteLink"><div className="quoteCard">
            <FontAwesomeIcon className="phoneIcon" icon={faPhone} size="2x"/>
                <div className="callText">
                    <p>GET A FREE QUOTE TODAY</p>
                    <p>717-733-3660</p>
                </div>
            </div></a>

            <section className="pageTextContent">
                <div className="homeAbout">
                    <img src="images/ls/IMG_2574.JPG" alt="The Team at L&S Truck Painting"></img>
                    <div>
                        <h2>About</h2>
                        <p>L&S Truck Painting is a commercial paint and body shop that was started in 2001, with a vision to create a paint shop with exceptional collision and paint repairs, in a timely manner. Whether it is pride of your truck getting a facelift or your top workhorse being involved in a accident, big or small we do them all. Your one stop shop for all your collision needs. We carry nothing but the best in paint coatings in the industry, which includes Axalta Imron and Sikkens. We have two down draft booths a 65ft long booth and the other is a 30ft long booth, to accommodate all your painting demands. Experience for yourself why we create lifelong customers and friendships here at L&S.</p>
                    </div>
                </div>
            </section>
            <section className="pageTextContent">
            <h2>We Offer</h2>
                <div className="homeLinks">
                    <li>
                        <Link to="/what-we-do">
                            <FontAwesomeIcon icon={faTruckMoving} size="7x"/>
                            Full Service Fleet Management
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact-us">
                            <FontAwesomeIcon icon={faMessage} size="7x"/>
                            Free Consultation, Free Quote
                        </Link>
                    </li>
                    <li>
                        <Link to="/gallery">
                            <FontAwesomeIcon icon={faSprayCan} size="7x"/>
                            Take a look at our work!
                        </Link>
                    </li>
                </div>
            </section>
            <section className="pageTextContent">
                
                <h2>Why Choose Us</h2>
                <div className="homeWhyChoose">
                    <div>
                        <h3>Quick Turnaround</h3>
                        <p>Quick turnaround so you can get back on the road.</p>
                    </div>
                    <div>
                        <h3>Quality</h3>
                        <p>No compromise on quality so you can trust the job to be done right</p>
                    </div>
                    <div>
                        <h3>Certified Body Shop</h3>
                        <p>We make your fleet look great through reliable repairs and ongoing maintenance</p>
                    </div>
                </div>
            </section>

            <section className="pageTextContent">
                <div className="homeContactUs">
                    <h2>Contact <br></br>L & S Truck Painting LLC at</h2>
                    <li><Link to="/contact-us">CONTACT US</Link></li>
                    <p>or give us a call at</p>
                    <a className="phone" href="tel:717-733-3660">717-733-3660</a>
                </div>

            </section>
        </>
    )
}

const Home = ({isMobile}) => {
    if (!isMobile) {
        return(
          <StyledHome>
            {CreateHtml()}
          </StyledHome>
        )
      } else {
        return(
          <StyledMobileHome>
            {CreateHtml()}
          </StyledMobileHome>
        )
      }
}

export default Home;