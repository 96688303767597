import Home from './components/Home'
import WhoWeAre from './components/WhoWeAre'
import WhatWeDo from './components/WhatWeDo'
import JobOpportunities from './components/JobOpportunities'
import Gallery from './components/Gallery'
import ContactUs from './components/ContactUs'
import Header from './components/Header'
import Apply from './components/Apply'
import NotFound from './components/NotFound'
import { useWindowWidth, ScrollToTop } from './components/Utilities'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Footer from './components/Footer'


function App() {

  const isMobile = (useWindowWidth() < 1073) ? true : false;

  return (
      <BrowserRouter>
        <ScrollToTop />
        <Header isMobile={isMobile}/>
        <Routes>
          <Route path="/" element={<Home isMobile={isMobile}/>}></Route>
          <Route path="/who-we-are" element={<WhoWeAre isMobile={isMobile}/>}></Route>
          <Route path="/what-we-do" element={<WhatWeDo isMobile={isMobile}/>}></Route>
          <Route path="/job-opportunities" element={<JobOpportunities isMobile={isMobile}/>}></Route>
          <Route path="/gallery" element={<Gallery isMobile={isMobile}/>}></Route>
          <Route path="/contact-us" element={<ContactUs isMobile={isMobile}/>} ></Route>
          <Route path="/apply" element={<Apply isMobile={isMobile}/>} ></Route>
          <Route path='*' element={<NotFound isMobile={isMobile}/>} />
        </Routes>
        <Footer isMobile={isMobile}/>
      </BrowserRouter>
  );
}

export default App;