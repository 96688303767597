import styled from "styled-components";

let offWhite = "#d7d3c4";
let lsRed = "#415248";

export const StyledFooter = styled.footer `

    border-top: 1px grey solid;

    font-size: ${props => props.isMobile ? '2.5vw' : '.8vw'};
    flex-direction: ${props => props.isMobile ? 'column' : 'row-reverse'};

    display: flex;
    padding: 2% 7% 2% 7%;
    background-color: ${offWhite};

    ul { 
        width: ${props => props.isMobile ? '100%' : '70%'};
        padding: 0; 
        text-align: center;
    }

    li {
        display: inline-block;
        list-style: none;
        margin: ${props => props.isMobile ? '0 1% 2% 1%' : '0 1% 0 1%'};
        white-space: nowrap;
    }

    a {
        text-decoration: none;
        color: #1f1e1e;
    }

    a:hover {
        font-weight: 500;
    }

    .highlightNav {
        box-shadow: 1px 1px 5px 1px grey;
        padding: 2px;
        background-color: ${lsRed};
        color: ${offWhite};
        font-weight: 600;
    }





    p {
        width: ${props => props.isMobile ? '100%' : '30%'};
        text-align: center;
    }

`
