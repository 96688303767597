import styled from "styled-components";

let offWhite = "#d7d3c4";
let lsGreen = "#415248";
let lsLightBrown = "#ac9c7b";

export const StyledJobOpportunities = styled.div `

    ${'' /* position: absolute;
    top: 0; */}
    display: flex;
    flex-direction: column;
    
        .pageHead {
            ${'' /* position: absolute;
            top: 0; */}
            background-image: url(images/ls/IMG_2575.JPG);
            width: 100%;
            background-size: cover;
            background-position: 0% 70%;
            background-color: rgba(0,104,178,.4);
            background-blend-mode: multiply;
        }
    
        .pageHead h1 {
            ${'' /* positional styling */}
            width: 60%;
            margin: 4em auto 3em auto;
            text-align: center;
            padding: 2% 4% 2% 4%;
    
            ${'' /* text styling */}
            line-height: 1.25em;
            font-size: 4vw;
            font-weight: 900;
            text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
            0px 8px 13px rgba(0,0,0,0.1),
            0px 18px 23px rgba(0,0,0,0.1);
            color: ${offWhite};
        }












    .pageTextContent {
        background-color: ${lsGreen};
        color: ${offWhite};
    }

    .pageTextContent div {
        margin: 7% 22% 7% 22%;
    }

    .pageTextContent h2 {
        line-height: 1.25em;
        font-size: 3vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .pageTextContent p {
        text-shadow: 0px 1px 2px rgba(0,0,0,0.4),
        0px 2px 11px rgba(0,0,0,0.1),
        0px 4px 20px rgba(0,0,0,0.1);
        font-size: 1.75vw;
    }









    .applyNow {
        padding: 5% 0 8% 0;
        background-color: ${lsLightBrown};
        color: ${offWhite};
        text-align: center;
        font-weight: 600;
        font-size: 1.25vw;
    }

    .applyNow h2 {
        line-height: 1.25em;
        font-size: 4vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .applyNow .contactUsButton {
        text-decoration: none;   
        margin: auto;
        padding: 1%;
        background-color: ${lsGreen};
        color: ${offWhite};
        display: block;
        width: 10%;

        line-height: 1.25em;
        box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        -webkit-box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        -moz-box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        transition: 0.3s;
    }

    .applyNow .contactUsButton:hover {
        width: 10.5%;
        box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
        -webkit-box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
        -moz-box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
    }

    .applyNow .email {
        text-decoration: underline;   
        color: ${lsGreen};
        font-weight: 550;
        transition: 0.3s;
    }

    .applyNow .email:hover {
        font-weight: 750;
    }

    .applyNow p {
        margin: 4% 0 2% 0;
        color: ${lsGreen};
    }
`