import styled from "styled-components";

let offWhite = "#d7d3c4";
let offBlack = "#030b24";
let lsGreen = "#415248";
let lsLightBrown = "#ac9c7b";

export const StyledContactUs = styled.div `

    display: flex;
    flex-direction: column;
    background-color: ${offWhite};

    .pageHead {
        background-image: url(images/ls/IMG_2576.JPG);
        background-size: cover;
        background-position: 0% 17%;
        padding: 2% 4% 2% 4%;
        background-color: rgba(0,104,178,.4);
        background-blend-mode: multiply;
    }

    .pageHead h1 {
        ${'' /* positional styling */}
        width: 30%;
        margin: 4em auto 4em auto;
        text-align: center;

        ${'' /* text styling */}
        line-height: 1.25em;
        font-size: 4vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        color: ${offWhite};
    }








    .quoteLink {
        position: absolute;
        right: 0;
        margin-top: 36.5%;
        margin-left: auto;
        width: 50%;
        text-decoration: none;
        transition: 0.3s;
    }

    .quoteLink:hover {
        width: 51%;
        box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.75);
        -webkit-box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.75);
    }

    .quoteCard {
        ${'' /* misc */}
        display: flex;
        background-color: ${lsLightBrown};
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        color: ${offBlack};
        font-size: 3vw;
    }

    .quoteCard .phone {
        text-decoration: none;
        text-shadow: 1px grey;
        color: black;
    }

    .phoneIcon {
        width: 15%;
        padding: 2%;
    }

    .quoteCard .callText {
        line-height: 1.25em;
        font-size: 1.5vw;
        font-weight: 900;
    }










    .pageTextContent {
        background-color: ${lsGreen};
        color: ${offWhite};
    }

    .pageTextContent div {
        margin: 7% 22% 7% 22%;
    }

    .pageTextContent ul {
        font-size: 1.5vw;
        list-style: none;
        padding-left: 0;
    }

    .pageTextContent li {
        margin-bottom: 1%;
        font-weight: 500;
    }

    .pageTextContent .bold {
        font-weight: 1000;
        color: ${lsLightBrown};
    }

    .pageTextContent h2 {
        line-height: 1.25em;
        font-size: 3vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .pageTextContent p {
        text-shadow: 0px 1px 2px rgba(0,0,0,0.4),
        0px 2px 11px rgba(0,0,0,0.1),
        0px 4px 20px rgba(0,0,0,0.1);
        ${'' /* font-weight: 550; */}
        font-size: 1.75vw;
    }





    .socialMedia {
        flex-direction: column;
        margin: 0 auto;
        font-size: 2vw;
    }

    .socialMedia div {
        display: flex;
        justify-content: center;
    }

    .socialMedia div a {
        margin: 0 4% 0 4%;
        transition: 0.5s;
        color: inherit;
    }

    .socialMedia div a:hover {
        opacity: .9;
        transform: scale(1.05);
    }





    .contactIcons {
        display: flex;
        margin: 5% 10% 5% 10%;
        text-align: center;
    }

    .contactIcons a {
        text-decoration: none;
        color: inherit;
        border-top: grey 1px solid;
        border-bottom: grey 1px solid;
        width: 50%;
        margin: 0% 2% 0% 2%;
        padding: 5% 0% 5% 0%;
        font-size: 1.5vw;
        transition: 0.5s;
    }

    .contactIcons a:hover {
        transform: scale(1.10);
    }

    .contactIcons p {
        font-weight: 300;
    }

    









    .contactForm {
        display: flex;
        background-color: ${lsGreen};
        padding: 2% 5% 2% 5%;
        color: ${offWhite};
        font-size: 3vw;
    }

    .contactForm h1 {
        text-align: center;
        margin-top: 15%;
    }

    .contactForm form {
        width: 50%;
        ${'' /* display: flex; */}
        justify-content: center;
    }

    .contactForm input {
        padding: 1%;
        margin: 1% 2% 1% 2%;
        margin-left: 0;
        font-size: .5em;
        color: ${lsGreen};
        width: 100%;
        background-color: ${offWhite};
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .contactForm textarea {
        margin-top: 2%;
        margin-bottom: 2%;
        padding: 1%;
        font-size: .5em;
        color: ${lsGreen};
        height: 12vh;
        width: 100%;
        resize: none;
        background-color: ${offWhite};
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .contactForm .submitButton {
        font-size: 1.8vw;
        margin-top: 5%;
        width: 100%;
        font-weight: 600;
        background-color: ${offWhite};
        color: ${lsGreen};
        transition: 0.3s;
    }

    .contactForm .submitButton:hover {
        transform: scale(1.03);
    }

    .contactForm h1 {
        line-height: 1.25em;
        font-size: 4.3vw;
        font-weight: 900;
    }

    .contactForm label {
        font-size: 1.5vw;
        font-weight: 700;
        margin-bottom: 0;
        margin-left: 2%;
    }











    .mapContainer {
        padding-left: 15%;
        padding-right: 15%;
        padding: 5% 15% 5% 15%;
        background-color: ${lsGreen};
    }

    .mapContainer a {
        text-decoration: none;
        font-styling: none;
        margin: 0 auto;
        
    }

    .mapContainer h3 {
        text-align: center;
        line-height: 1.25em;
        font-size: 2vw;
        font-weight: 700;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        color: ${offWhite};
        transition: 0.3s;
    }

    .mapContainer h3:hover {
        transform: scale(1.05);
    }
`