import styled from "styled-components";

let offWhite = "#d7d3c4";
let lsGreen = "#415248";

export const StyledApply = styled.div `

    display: flex;
    flex-direction: column;
    background-color: ${offWhite};


    .pageHead {
        background-image: url(images/ls/IMG_2576.JPG);
        background-size: cover;
        background-position: 0% 17%;
        padding: 2% 4% 2% 4%;
        background-color: rgba(0,104,178,.4);
        background-blend-mode: multiply;
    }

    .pageHead h1 {
        ${'' /* positional styling */}
        margin: ${props => props.isMobile ? '2em auto 2em auto' : '4em auto 2em auto'};
        text-align: center;

        ${'' /* text styling */}
        line-height: 1.25em;
        font-size: ${props => props.isMobile ? '8vw' : '4vw'};
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        color: ${offWhite};
    }









    .contactForm {
        display: flex;
        background-color: ${lsGreen};
        padding: ${props => props.isMobile ? '5% 10% 5% 10%' : '5% 20% 5% 20%'};
        text-align: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .contactForm div {
        width: 98%;
        text-align: left;
        margin-top: 4%;
    }

    .contactForm div label {
        color: ${offWhite};
        font-size: ${props => props.isMobile ? '4vw' : '1.5vw'};
        font-weight: 600;
    }

    .contactForm .half {
        margin: 4% 1% 0 1%;
        width: ${props => props.isMobile ? '100%' : '48%'};
    }

    .contactForm .half input {
        font-size: ${props => props.isMobile ? '5vw' : '1.2vw'};
        padding: ${props => props.isMobile ? '1%' : '3.1%'};
        background-color: ${offWhite};
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        color: ${lsGreen};
    }

    .contactForm textarea {
        width: 100%;
        height: 12vh;
        padding: 2%;
        color: ${lsGreen};
        resize: none;
        font-size: ${props => props.isMobile ? '4vw' : '1.2vw'};
        background-color: ${offWhite};
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .contactForm select {
        padding: ${props => props.isMobile ? '2vw' : '1vw'};
        width: 100%;
        font-size: ${props => props.isMobile ? '3vw' : '1vw'};
        background-color: ${offWhite};
    }

    .contactForm .submitButton {
        font-size: ${props => props.isMobile ? '5vw' : '1.8vw'};
        margin-top: 5%;
        width: ${props => props.isMobile ? '90%' : '30%'};
        font-weight: 600;
        background-color: ${offWhite};
        color: ${lsGreen};
        transition: 0.3s;

    }

    .contactForm .submitButton:hover {
        transform: scale(1.03);
    }
`