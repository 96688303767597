import styled from "styled-components";

let offWhite = "#d7d3c4";
let offBlack = "#030b24";
let lsGreen = "#415248";
let lsLightBrown = "#ac9c7b";

export const StyledMobileWhoWeAre = styled.div `

    display: flex;
    flex-direction: column;
    background-color: ${offWhite};

    ${'' /* position: absolute;
    top: 0; */}
    top: 0;

    .pageHead {
        background-image: url(images/ls/IMG_2580.JPG);
        background-size: 185vw;
        background-color: rgba(0,104,178,.4);
        background-blend-mode: multiply;
        ${'' /* background-position: -10% 60%; */}
        padding: 0 0 5% 0;
        background-position: 100% 85%;
    }

    .pageHead h1 {
        ${'' /* positional styling */}
        width: 70%;
        margin: 0 auto;
        margin-top: 10%;
        margin-bottom: 30%;
        text-align: center;

        ${'' /* border box styling */}
        text-align: center;
        padding: 10% 4% 10% 4%;

        ${'' /* text styling */}
        line-height: 1.25em;
        font-size: 10vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        color: ${offWhite};
    }








    .quoteLink {
        text-decoration: none;
        align-items: center;
        box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
    }

    .quoteCard {
        display: flex;
        background-color: ${lsLightBrown};
        color: ${offBlack};
        justify-content: center;
    }

    .phoneIcon {
        width: 15%;
        padding: 1%;
        padding-top: 2%;
        height: 10vw;
    }

    .quoteCard img {
        width: 13%;
        padding: 2%;
    }

    .quoteCard .callText {
        line-height: 1.25em;
        font-size: 3vw;
        font-weight: 900;
    }










    .pageTextContent {
        background-color: ${lsGreen};
        color: ${offWhite};
    }

    .pageTextContent div {
        margin: 18% 10% 18% 10%;
    }

    .pageTextContent h2 {
        line-height: 1.25em;
        font-size: 7vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .pageTextContent h3 {
        margin-top: 15%;
        line-height: 1.25em;
        font-size: 5vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .pageTextContent p, li {
        text-shadow: 0px 1px 2px rgba(0,0,0,0.4),
        0px 2px 11px rgba(0,0,0,0.1),
        0px 4px 20px rgba(0,0,0,0.1);
        font-weight: 550;
        font-size: 3.5vw;
    }








    .servicesListContent {
        background-color: ${offWhite};
        color: ${offBlack};
        padding: 5% 0 0 0;
    }

    .servicesListContent .containerDiv {

    }

    .servicesListContent h2 {
        text-align: center; 
        line-height: 1.25em;
        font-size: 9vw;
        font-weight: 900;
    }

    .servicesListContent .wrapItems {
        display: flex;
        flex-direction: column;
        border-top: 0.05em solid gray;
        padding: 10% 0 10% 0;
    }

    .servicesListContent #red {
        background-color: rgba(255, 234, 234, 0.80);
    }

    .servicesListContent .wrapText {
        margin: 0 10% 0 10%;
    }

    .servicesListContent .wrapText p:nth-child(2) {
        font-size: .65em;
    }

    .servicesListContent img {
        width: 10%;
        margin: 0 10%;
    }

    .servicesListContent h3 {
        line-height: 1.25em;
        font-size: 5vw;
        font-weight: 900;

    }

    .servicesListContent p {
        font-weight: 550;
        font-size: 3.8vw;
    }






    .getFreeQuote {
        padding: 5% 0 8% 0;
        background-color: ${lsLightBrown};
        color: ${offWhite};
        text-align: center;
        font-weight: 600;
        font-size: 4vw;
    }

    .getFreeQuote h2 {
        line-height: 1.25em;
        font-size: 9vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .getFreeQuote .contactUsButton {
        text-decoration: none;   
        margin: auto;
        padding: 3%;
        background-color: ${lsLightBrown};
        color: ${offBlack};
        display: block;
        width: 30%;

        line-height: 1.25em;
        box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        -webkit-box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        -moz-box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        transition: 0.3s;
    }

    .getFreeQuote .contactUsButton:hover {
        width: 30.5%;
        box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
        -webkit-box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
        -moz-box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
    }

    .getFreeQuote .phone {
        text-decoration: none;   
        color: ${lsLightBrown};
        font-weight: 550;
        padding-top: 5%;
        transition: 0.3s;
    }

    .getFreeQuote .phone:hover {
        font-weight: 750;
    }

    .getFreeQuote p {
        margin: 9% 0 2% 0;
        color: rgba(255, 188, 188, 0.94);
    }







    .experienceData {
        display: flex;
        flex-direction: column;
        padding: 10% 10% 10% 10%;
        color: ${offWhite};
        background-color: ${lsGreen};

        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .experienceData div {
        border: 0.07em solid rgba(204, 219, 255, 0.66);
        width: 100%;
        text-align: center;
    }

    .experienceData h3 {
        line-height: 1.25em;
        font-size: 10vw;
        font-weight: 900;
        margin: 5% 0 5% 0;
    }

    .experienceData p {
        font-size: 5vw;
        font-weight: 550;
    }
`