import { StyledNotFound } from "./styles/NotFound.styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const NotFound = ({isMobile}) => {
    return(
        <StyledNotFound isMobile={isMobile}>
            <FontAwesomeIcon icon={faTriangleExclamation} size="5x"/>
            <p>Page Not found</p>
        </StyledNotFound>
    )
}

export default NotFound;