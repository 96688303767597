import { StyledGallery } from "./styles/Gallery.styled"
import { StyledMobileGallery } from "./mobileStyles/MobileGallery.styled"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft, faCircleChevronRight, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

function CreateHtml() {

    const [slideNumber, setSlideNumber] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const prevSlide = () => {
        slideNumber === 0 ? setSlideNumber(images.length - 1) : setSlideNumber(slideNumber - 1)
    }

    const nextSlide = () => {
        slideNumber === images.length - 1 ? setSlideNumber(0) : setSlideNumber(slideNumber + 1)
    }

    return(
        <>
            <h1>Check out our work</h1>

            {/* This fullscreen part only appears when you click an image (only if openModal is true) */}
            {openModal && 
                <div className='sliderWrap'>
                    <FontAwesomeIcon icon={faCircleXmark} className='btnClose' size="2x" onClick={handleCloseModal}/>
                    <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' size="2x" onClick={prevSlide}/>
                    <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' size="2x" onClick={nextSlide}/>
                    <div className="fullScreenImage">
                        <img src={images[slideNumber].img} alt='' />
                    </div>
                </div>
            }

            {/* This is the Gallery itself */}
            <div className="galleryContainer">
                {
                    images && images.map((slide, index) => {
                        return(
                            <div className="single" key={index} onClick={() => handleOpenModal(index)}>
                                <img src={slide.img} alt='' />
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

const Gallery = ({isMobile}) => {
    if (!isMobile) {
      return(
        <StyledGallery>
          {CreateHtml()}
        </StyledGallery>
      )
    } else {
      return(
        <StyledMobileGallery>
          {CreateHtml()}
        </StyledMobileGallery>
      )
    }
  }


  const images = [
    {
        img: 'images/ls/3ABF589F-FE56-4530-9BDF-637CC52CF35B.JPG',
    },
    {
        img: 'images/ls/9A05D6B7-665C-40F5-BD54-0F8A52DB58BC.JPG',
    },
    {
        img: 'images/ls/4069DEDB-ABEF-46C4-8565-FC475E9271DD.JPG',
    },
    {
        img: 'images/ls/IMG_1631.JPG',
    },
    {
        img: 'images/ls/IMG_1739.JPG',
    },
    {
        img: 'images/ls/IMG_1766.JPG',
    },
    {
        img: 'images/ls/IMG_1767.JPG',
    },
    {
        img: 'images/ls/IMG_1841.JPG',
    },
    {
        img: 'images/ls/IMG_1891.JPG',
    },
    {
        img: 'images/ls/IMG_2279.JPG',
    },
    {
        img: 'images/ls/IMG_2379.JPG',
    },
    {
        img: 'images/ls/IMG_2573.JPG',
    },
    {
        img: 'images/ls/C5C11B36-1FD9-4149-8369-D0BF85483571.JPG',
    },
    {
        img: 'images/ls/C752A814-52C4-4B43-9686-B5D9BB4C5A51.JPG',
    },
    {
        img: 'images/ls/EC196075-1935-471D-A98E-AA7BAE230D38.JPG',
    },
    {
        img: 'images/ls/IMG_1188.JPG',
    },
    {
        img: 'images/ls/AC306CF9-52AF-409A-AD26-67478471269C.JPG',
    },
    {
        img: 'images/ls/BBC55134-7443-4A04-B60C-8A961CF80B41.JPG',
    },
    {
      img: 'images/ls/redTruck.JPG',
    },
    {
        img: 'images/ls/FEA93716-3A3B-4729-83F7-8C42C7D0EDFB.JPG',
    },
    {
        img: 'images/ls/6DB0CB26-CD8D-4863-B50B-FFA053B2A11C.JPG',
    },
    {
        img: 'images/ls/36FF9346-221B-4F36-B024-B9DBF4B28473.JPG',
    },
    // {
    //     img: 'images/ls/IMG_1955.JPG',
    // },
    // {
    //     img: 'images/ls/IMG_2574.JPG',
    // },
    // {
    //     img: 'images/ls/IMG_2575.JPG',
    // },
    // {
    //     img: 'images/ls/IMG_2576.JPG',
    // },
    // {
    //     img: 'images/ls/IMG_2578.JPG',
    // },
    // {
    //     img: 'images/ls/IMG_2580.JPG',
    // },
    // {
    //   img: 'images/ls/IMG_2572.JPG',
    // },
]    
export default Gallery;