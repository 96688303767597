import styled from "styled-components";

let offWhite = "#d7d3c4";
let lsGreen = "#415248";

// ᕙ(▀̿ĺ̯▀̿ ̿)ᕗ

export const StyledHeader = styled.header `


    display: flex;
    box-shadow: 1px 0px 10px 1px grey;
    position: absolute;
    width: 100%;
    ${'' /* padding: 0 10% 0 10%; */}
    top: 0;
    z-index: 1;
    font-weight: 500;
    ${'' /* background-color: #f2f2f2; */}
    background-color: rgba(215,211,196, 0.8);
    ${'' /* background-color: rgba(5, 0, 255, 0.18); */}
    justify-content: space-between;
    flex-direction: column;
    font-size: 1vw;

    div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: .5px solid gray;
    }

    #imgLink {
        width: 18%;
        margin: .5% 0 .5% 10%;
        transition: 0.3s;
    }

    #imgLink:hover {
        opacity: 0.9;
    }

    img {
        width: 100%;
    }

    .phone {
        text-decoration: none;
        text-shadow: 1px grey;
        margin-top: 3%;
    }

    nav a {
        text-decoration: none;
        transition: 0.3s;
        padding: 5px 10% 5px 10%;
    }

    nav a:hover {
        ${'' /* if you change this, you must also change .highlightNav */}
        box-shadow: 1px 1px 5px 1px grey;
        background-color: ${lsGreen};
        color: ${offWhite};
        ${'' /* font-weight: 800; */}
    }

    ul { 
        padding: 0; 
        text-align: center;
    }

    li {
        display: inline-block;
        list-style: none;
        margin: 0 2% 0 2%;
        white-space: nowrap;
    }

    a {
        text-decoration: none;
        color: #1f1e1e;
    }

    .hamburger {
        display: none;
    }

    .highlightNav {
        box-shadow: 1px 1px 5px 1px grey;
        background-color: ${lsGreen};
        color: ${offWhite};
        ${'' /* font-weight: 800; */}
    }

    .socalMediaIconContainer {
        margin-top: 5%;
        margin-right: 5%;
        width: 18%;
    }

    .socalMediaIconContainer a {
        ${'' /* padding: 0% 1% 0% 1%; */}
        transition: 0.5s;
    }

    .socalMediaIconContainer a:hover {
        opacity: .9;
        transform: scale(1.05);
    }
`
