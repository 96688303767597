import styled from "styled-components";

let offWhite = "#d7d3c4";
let lsGreen = "#415248";
let lsLightBrown = "#ac9c7b";

export const StyledMobileGallery = styled.div `

background-color: ${lsGreen};
padding: 5% 0% 5% 0%;

h1 {
        ${'' /* positional styling */}
        width: 60%;
        margin: 0 auto;

        margin-bottom: 5%;
        text-align: center;

        ${'' /* border box styling */}
        text-align: center;
        padding: 2% 4% 2% 4%;
        background-color: ${lsLightBrown};
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);

        ${'' /* text styling */}
        line-height: 1.25em;
        font-size: 6vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        color: ${offWhite};
    }


    .galleryContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .galleryContainer .single {
        width: 45%;
        cursor: pointer;
    }

    .galleryContainer .single img {
        max-width: 100%;
        transition: 0.1s;
        border: 1px solid white;
    }

    .galleryContainer .single img:hover {
        transform: scale(1.02);
    }


    .sliderWrap {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-color: rgba(9, 1, 5, 0.75);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
        position: fixed;
        cursor: pointer;
        opacity: 0.6;
        color: #fff;
        z-index: 9999;
    }

    .sliderWrap .btnClose {
        top: 40px;
        right: 40px;
    }

    .sliderWrap .btnPrev {
        top: 50%;
        transform: translateY(-50%);
        left: 50px;
    }

    .sliderWrap .btnNext {
        top: 50%;
        transform: translateY(-50%);
        right: 50px;
    }

    .fullScreenImage {
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fullScreenImage img {
        max-width: 100%;
        max-height: 100%;
        pointer-events: none;
        user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;  
    }
`