import styled from "styled-components";

let offWhite = "#d7d3c4";
let offBlack = "#030b24";
let lsGreen = "#415248";
let lsLightBrown = "#ac9c7b";
let lsGreenFade = "rgba(0, 104, 178, 0.85)";

export const StyledHome = styled.div `

    ${'' /* position: absolute;
    top: 0; */}
    display: flex;
    flex-direction: column;
    min-width: 100%;

    .pageHead {
        ${'' /* position: absolute;
        top: 0; */}
        background-image: url(images/ls/IMG_2572.JPG);
        width: 100%;
        height: 48vw;
        background-size: cover;
        background-position: 0% 40%;
        padding: 0 0 5% 0;
        ${'' /* background-color: rgba(65,82,72,.3); */}
        background-blend-mode: multiply;
    }

    .pageHead li {
        ${'' /* positional styling */}
        width: 9%;
        margin: 0 auto;
        margin-bottom: 25%;
        list-style: none;
        ${'' /* text styling */}
        color: ${offWhite};
    }

    .pageHead li a {
        ${'' /* positional styling */}
        margin: 0 auto;
        padding: .75em;
        text-align: center;
        background-color: ${lsLightBrown};
        ${'' /* text styling */}
        line-height: 1.25em;
        font-size: 1vw;
        font-weight: 900;
        color: ${offWhite};
        text-decoration: none;

    }

    .pageHead li a:hover {
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.55);
        transition: 0.5s;
    }

    .pageHead p {
        ${'' /* positional styling */}
        width: 60%;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 2%;
        ${'' /* background-color: ${lsLightBrown}; */}
        // background-color: ${lsGreenFade};

        ${'' /* text styling */}
        line-height: 1.25em;
        font-size: 2vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        color: ${offWhite};
    }

    .pageHead h1 {
        ${'' /* positional styling */}
        width: 60%;
        margin: 0 auto;
        margin-top: 10%;
        text-align: center;
        padding: 2% 4% 2% 4%;
        ${'' /* background-color: ${lsLightBrown}; */}
        // background-color: ${lsGreenFade};

        ${'' /* text styling */}
        line-height: 1.25em;
        font-size: 4vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        color: ${offWhite};
    }



    .quoteCard {
        ${'' /* positional styling */}
        position: absolute;
        right: 0;
        margin-top: -4.5%;
        margin-left: auto;
        width: 50%;

        ${'' /* misc */}
        display: flex;
        background-color: ${lsLightBrown};
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        transition: 0.3s;
        color: ${offBlack};

        font-size: 3vw;
    }

    .quoteCard:hover {
        width: 51%;
        box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.75);
        -webkit-box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.75);
    }

    .quoteCard .phone {
        text-decoration: none;
        text-shadow: 1px grey;
        color: ${lsLightBrown};
    }

    .phoneIcon {
        width: 15%;
        padding: 2%;
    }

    .quoteCard .callText {
        line-height: 1.25em;
        font-size: 1.5vw;
        font-weight: 900;
        ${'' /* text-shadow: 2px 3px 5px rgba(0,0,0,0.5); */}
    }


    .pageTextContent {
        background-color: ${lsGreen};
        color: ${offWhite};
    }


    .pageTextContent h2 {
        line-height: 1.25em;
        font-size: 3vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        display:flex;
        justify-content: center;
    }

    .pageTextContent h3 {
        margin-top: 5%;

        line-height: 1.25em;
        font-size: 2vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .pageTextContent p, li {
        text-shadow: 0px 1px 2px rgba(0,0,0,0.4),
        0px 2px 11px rgba(0,0,0,0.1),
        0px 4px 20px rgba(0,0,0,0.1);
        font-weight: 550;
        font-size: 1.25vw;
    }

    .pageTextContent ul {
        margin-bottom: 10%;
    }

    .pageTextContent img {
        max-width:50%;
        height:auto;
    }



    .homeAbout {
        display:flex;
        flex-direction: row;
        margin: 10% 10% 7% 10%;
        justify-content: space-between;
    }

    .homeAbout div{
        margin: 0 0 0 3em;
    }

    .homeLinks {
        display: flex;
        list-style: none;
        width: 100%;
        margin: 0 0 5em 0;
    }

    .homeLinks li {
        width: 100%;
        height: 30em;
        padding: 0;
        transition-duration: .5s;
        background: linear-gradient(
        rgb(172,156,123, 0.85),
        rgb(172,156,123, 0.85)), 
        url("images/ls/IMG_1891.JPG");
        background-attachment: fixed;
        background-position: 0% 50%;
        background-size: cover;
    }

    .homeLinks li a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-decoration: none;
        color: ${offWhite};
        width: 100%;
        font-size: 1.5em;
    }

    .homeLinks li a > * {
        margin: .25em;
    }

    .homeLinks li a:hover {
        transition-duration: .5s;
        background-color: ${lsGreen};
    }

    .homeWhyChoose {
        display: flex;
        justify-content: center;
        margin: 0 0 4em 0;
    }
    

    .homeWhyChoose div {
        width: 30%;
        margin: 0 5em 10em 5em;
        height: 5em;
    }

    .homeWhyChoose div h3 {
        color: ${offWhite};
    }



    .homeContactUs {
        background-image: url(images/ls/AC306CF9-52AF-409A-AD26-67478471269C.JPG);
        background-position: 0% 70%;
        background-size: cover;
        background-attachment: fixed;
        background-color: rgba(0,104,178,.5);
        background-blend-mode: multiply;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0 10em 0;
    }

    .homeContactUs h2 {
        padding: 2em 0 0 0;
        text-align: center;
    }

    .homeContactUs li {
        ${'' /* positional styling */}
        width: 9%;
        margin: 0 0 1em 0;
        list-style: none;
        ${'' /* text styling */}
        color: ${offWhite};
    }

    .homeContactUs li a {
        ${'' /* positional styling */}
        margin: 0 auto;
        padding: .75em;
        text-align: center;
        background-color: ${lsGreen};
        ${'' /* text styling */}
        line-height: 1.25em;
        font-size: 1vw;
        font-weight: 900;
        color: ${offWhite};
        text-decoration: none;

    }

    .homeContactUs li a:hover {
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.55);
        transition: 0.5s;
    }

    .homeContactUs p {
        margin: 1em 0 .5em 0;
    }

    .homeContactUs a {
        font-size: 2em;
        color: ${offWhite};
    }





    .servicesListContent {
        background-color: ${offWhite};
        color: ${offBlack};
        padding: 3% 0 5% 0;
    }

    .servicesListContent .containerDiv {
        margin: 0 15% 0 15%;
    }

    .servicesListContent h2 {
        text-align: center; 
        line-height: 1.25em;
        font-size: 4vw;
        font-weight: 900;
    }

    .servicesListContent .wrapItems {
        display: flex;
        margin: 0 0 0 0;
        border-top: 0.05em solid gray;
        padding: 2% 0 2% 0;
    }

    .servicesListContent .wrapText {
        margin-left: 5%;
        width: 70%;
    }

    .servicesListContent .wrapText p:nth-child(2) {
        font-size: 1em;
    }
    

    .servicesListContent img {
        width: 5%;
    }

    .servicesListContent h3 {
        line-height: 1.25em;
        font-size: 2vw;
        font-weight: 900;

    }

    .servicesListContent p {
        font-weight: 550;
        font-size: 1.25vw;
    }






    .getFreeQuote {
        padding: 5% 0 8% 0;
        background-color: ${lsLightBrown};
        color: ${offWhite};
        text-align: center;
        font-weight: 600;
        font-size: 1.25vw;
    }

    .getFreeQuote h2 {
        line-height: 1.25em;
        font-size: 4vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .getFreeQuote .contactUsButton {
        text-decoration: none;   
        margin: auto;
        padding: 1%;
        color: ${offBlack};
        display: block;
        width: 10%;

        line-height: 1.25em;
        box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        -webkit-box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        -moz-box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        transition: 0.3s;
    }

    .getFreeQuote .contactUsButton:hover {
        width: 10.5%;
        box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
        -webkit-box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
        -moz-box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
    }

    .getFreeQuote .phone {
        text-decoration: none;   
        font-weight: 550;
        transition: 0.3s;
    }

    .getFreeQuote .phone:hover {
        font-weight: 750;
    }

    .getFreeQuote p {
        margin: 4% 0 2% 0;
        color: rgba(255, 188, 188, 0.94);
    }







    .experienceData {
        display: flex;
        padding: 5% 15% 5% 15%;
        color: ${offWhite};
        background-color: ${lsGreen};

        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .experienceData div {
        border: 0.07em solid rgba(204, 219, 255, 0.66);
        width: 25%;
        text-align: center;
    }

    .experienceData h3 {
        line-height: 1.25em;
        font-size: 4vw;
        font-weight: 900;
        margin: 15% 0 1% 0;
    }

    .experienceData p {
        font-size: 1.5vw;
        font-weight: 550;
    }


    footer {
        background-color: ${lsLightBrown};
        height: 7em;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${offWhite};
    }

    footer section {
        display: flex;
    }

    footer section ul li {
        background-color: clear;
        list-style: none;
        color: ${offWhite};
        margin: 0 1em 0 0;
    }

    footer li a {
        ${'' /* positional styling */}
        margin: 0 auto;
        text-align: center;
        ${'' /* text styling */}
        line-height: 1.25em;
        font-size: 1vw;
        font-weight: 900;
        color: ${offWhite};
        text-decoration: none;

    }

    footer li a:hover {
        text-shadow: 0px 0px 5px white;
    }

    footer section ul {
        display: flex;
    }
`