import styled from "styled-components";

let offWhite = "#d7d3c4";
let lsGreen = "#415248";

export const StyledMobileJobOpportunities = styled.div `

    display: flex;
    flex-direction: column;

    .pageHead {
        background-image: url(images/ls/IMG_2575.JPG);
        background-size: 135vw;
        background-color: rgba(0,104,178,.4);
        background-blend-mode: multiply;
        ${'' /* background-position: -10% 60%; */}
        padding: 0 0 5% 0;
        background-position: 60% 20%;
    }

    .pageHead h1 {
        ${'' /* positional styling */}
        width: 70%;
        margin: 0 auto;
        margin-top: 10%;
        margin-bottom: 10%;
        text-align: center;

        ${'' /* border box styling */}
        text-align: center;
        padding: 10% 4% 10% 4%;

        ${'' /* text styling */}
        line-height: 1.25em;
        font-size: 10vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        color: ${offWhite};
    }








    ${'' /* .quoteCard {
        position: absolute;
        margin-top: 73%;
        padding-left: 20%;
        width: 80%;
        align-items: center;
        display: flex;
        background-color: ${lsLightBrown};
        transition: 0.3s;
        color: ${offBlack};
        text-align: center;
        box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.75);
    }

    .quoteCard:hover {
        width: 100%;
        box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.75);
        -webkit-box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.75);
    }

    .quoteCard .phone {
        text-decoration: none;
        text-shadow: 1px grey;
        color: black;
    }

    .quoteCard img {
        width: 13%;
        padding: 2%;
    }


    .phoneIcon {
        width: 15%;
        padding: 1%;
        height: 10vw;
    }

    .quoteCard .callText {
        line-height: 1.25em;
        font-size: 3vw;
        font-weight: 900;
        padding-bottom: 4%;
    }
 */}





    .pageTextContent {
        background-color: ${lsGreen};
        color: ${offWhite};
    }

    .pageTextContent div {
        margin: 18% 10% 18% 10%;
    }

    .pageTextContent h2 {
        line-height: 1.25em;
        font-size: 7vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .pageTextContent h3 {
        margin-top: 15%;
        line-height: 1.25em;
        font-size: 5vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .pageTextContent p, li {
        text-shadow: 0px 1px 2px rgba(0,0,0,0.4),
        0px 2px 11px rgba(0,0,0,0.1),
        0px 4px 20px rgba(0,0,0,0.1);
        font-weight: 550;
        font-size: 3.5vw;
    }








    .applyNow {
        padding: 5% 0 8% 0;
        background-color: ${offWhite};
        color: ${lsGreen};
        text-align: center;
        font-weight: 600;
        font-size: 3vw;
    }

    .applyNow h2 {
        line-height: 1.25em;
        font-size: 9vw;
        font-weight: 900;
    }

    .applyNow .contactUsButton {
        text-decoration: none;   
        margin: auto;
        padding: 3%;
        background-color: ${lsGreen};
        color: ${offWhite};
        display: block;
        width: 30%;

        line-height: 1.25em;
        box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        -webkit-box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        -moz-box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        transition: 0.3s;
    }

    .applyNow .contactUsButton:hover {
        width: 30.5%;
        box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
        -webkit-box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
        -moz-box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
    }

    .applyNow .email {
        text-decoration: underline;   
        color: ${lsGreen};
        font-weight: 550;
        padding-top: 5%;
        transition: 0.3s;
    }

    .applyNow .email:hover {
        font-weight: 750;
    }

    .applyNow p {
        margin: 9% 0 2% 0;
        color: ${lsGreen};
    }
`