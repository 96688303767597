import styled from "styled-components";

let offWhite = "#d7d3c4";
let offBlack = "#030b24";
let lsGreen = "#415248";
let lsLightBrown = "#ac9c7b";
let lsBlueFade = "rgba(0, 104, 178, 0.85)";

export const StyledWhatWeDo = styled.div `


    ${'' /* position: absolute;
    top: 0; */}
    display: flex;
    flex-direction: column;

    .pageHead {
        ${'' /* position: absolute;
        top: 0; */}
        background-image: url(images/ls/IMG_1841.JPG);
        width: 100%;
        background-size: cover;
        background-position: 0% 39%;
        background-color: rgba(0,104,178,.2);
        background-blend-mode: multiply;
    }

    .pageHead h1 {
        ${'' /* positional styling */}
        width: 60%;
        margin: 4em auto 4em auto;
        text-align: center;
        padding: 2% 4% 2% 4%;
        ${'' /* background-color: ${lsRed}; */}
        // background-color: ${lsBlueFade};

        ${'' /* text styling */}
        line-height: 1.25em;
        font-size: 4vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
        color: ${offWhite};
    }






    .quoteLink {
        position: absolute;
        right: 0;
        margin-top: 36.5%;
        margin-left: auto;
        width: 50%;
        text-decoration: none;
        transition: 0.3s;
    }

    .quoteLink:hover {
        width: 51%;
        box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.75);
        -webkit-box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 20px 10px rgba(0,0,0,0.75);
    }

    .quoteCard {
        ${'' /* misc */}
        display: flex;
        background-color: ${lsLightBrown};
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        color: ${offBlack};
        font-size: 3vw;
    }

    .quoteCard .phone {
        text-decoration: none;
        text-shadow: 1px grey;
        color: black;
    }

    .phoneIcon {
        width: 15%;
        padding: 2%;
    }

    .quoteCard .callText {
        line-height: 1.25em;
        font-size: 1.5vw;
        font-weight: 900;
        ${'' /* text-shadow: 2px 3px 5px rgba(0,0,0,0.5); */}
    }








    .pageTextContent {
        background-color: ${lsGreen};
        color: ${offWhite};
    }

    .pageTextContent div {
        margin: 7% 22% 0 22%;
    }

    .pageTextContent h2 {
        line-height: 1.25em;
        font-size: 3vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .pageTextContent h3 {
        margin-top: 5%;

        line-height: 1.25em;
        font-size: 2vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .pageTextContent p, li {
        text-shadow: 0px 1px 2px rgba(0,0,0,0.4),
        0px 2px 11px rgba(0,0,0,0.1),
        0px 4px 20px rgba(0,0,0,0.1);
        ${'' /* font-weight: 550; */}
        font-size: 1.75vw;
    }

    .pageTextContent ul {
        margin-bottom: 10%;
    }








    .servicesListContent {
        background-color: ${offWhite};
        color: ${lsGreen};
        padding: 3% 0 5% 0;
    }

    .servicesListContent .containerDiv {
        margin: 0 15% 0 15%;
    }

    .servicesListContent h2 {
        text-align: center; 
        line-height: 1.25em;
        font-size: 4vw;
        font-weight: 900;
    }

    .servicesListContent .wrapItems {
        display: flex;
        margin: 0 0 0 0;
        border-top: 0.05em solid ${lsGreen};
        padding: 2% 0 2% 0;
    }

    .servicesListContent .wrapItems #dupont {
        width: 25%;
        height: 25%;
        display: flex;
        align-self: center;
        margin-left: 1em;
    }

    .servicesListContent .wrapText {
        margin-left: 5%;
        width: 70%;
    }

    .servicesListContent img {
        width: 27%;
    }

    .servicesListContent h3 {
        line-height: 1.25em;
        font-size: 2vw;
        font-weight: 900;

    }

    .servicesListContent p {
        font-weight: 550;
        font-size: 1.25vw;
    }


    #mixingRooms {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
    }

    #mixingRooms div {
        width: 50%;
        display: flex;
        align-items: center;
        padding: 0 3%;
    }

    #mixingRooms div p {
        font-size: 2vw;
        text-align: center;
    }


    #logos {
        margin-top: 3vw;
        display: flex;
        justify-content: center;
    }

    #logos img:nth-child(odd) {
        height: auto;
        padding: 1vw;
        width: 20%;
    }

    #logos img:nth-child(even) {
        height: auto;
        padding: 1vw;
        width: 30%;
    }
    






    .getFreeQuote {
        padding: 5% 0 8% 0;
        background-color: ${lsLightBrown};
        color: ${offWhite};
        text-align: center;
        font-weight: 600;
        font-size: 1.25vw;
    }

    .getFreeQuote h2 {
        line-height: 1.25em;
        font-size: 4vw;
        font-weight: 900;
        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .getFreeQuote .contactUsButton {
        text-decoration: none;   
        margin: auto;
        padding: 1%;
        background-color: ${lsGreen};
        color: ${offWhite};
        display: block;
        width: 10%;
        line-height: 1.25em;
        box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        -webkit-box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        -moz-box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.43);
        transition: 0.3s;
    }

    .getFreeQuote .contactUsButton:hover {
        width: 10.5%;
        box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
        -webkit-box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
        -moz-box-shadow: 7px 7px 7px 4px rgba(0,0,0,0.43);
    }

    .getFreeQuote .phone {
        text-decoration: underline;  
        color: ${lsGreen};
        font-weight: 550;
        transition: 0.3s;
    }

    .getFreeQuote .phone:hover {
        font-weight: 750;
    }

    .getFreeQuote p {
        margin: 4% 0 2% 0;
        color: ${lsGreen};
    }







    .experienceData {
        display: flex;
        padding: 5% 15% 5% 15%;
        color: ${offWhite};
        background-color: ${lsGreen};

        text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
        0px 8px 13px rgba(0,0,0,0.1),
        0px 18px 23px rgba(0,0,0,0.1);
    }

    .experienceData div {
        border: 0.07em solid rgba(204, 219, 255, 0.66);
        width: 25%;
        text-align: center;
    }

    .experienceData h3 {
        line-height: 1.25em;
        font-size: 4vw;
        font-weight: 900;
        margin: 15% 0 1% 0;
    }

    .experienceData p {
        font-size: 1.5vw;
        font-weight: 550;
    }
`