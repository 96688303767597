import { StyledApply } from "./styles/Apply.styled"
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Apply = ({isMobile}) => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_4wlp7do', 'template_ehun0ui', form.current, 'L8f_TdEgfuJODLB9p')
          .then((result) => {
              console.log(result.text);
              alert("Thank you, the form was submitted successfully.");
          }, (error) => {
              console.log(error.text);
              alert("Sorry there was an error, please email us directly at lynn@landstruckpainting.net");
          });
          e.target.reset()
    };

    return(
        <StyledApply isMobile={isMobile}>
            <section className="pageHead">
                <h1>Apply today</h1>
            </section>

            <form ref={form} onSubmit={sendEmail} className="contactForm" method="POST">
                <input type="hidden" name="email_type" value="Job Application"/>
                <div className="half">
                    <label htmlFor="from_name">Full Name*</label>
                    <input type="text" name="from_name" required="required"/>
                </div>
                <div className="half">
                    <label htmlFor="from_email">Email*</label>
                    <input type="email" name="from_email" required="required"/>
                </div>
                <div className="half">
                    <label htmlFor="from_phone">Phone Number*</label>
                    <input type="tel" name="from_phone" required="required"/>
                </div>
                <div className="half">
                    <label htmlFor="from_position">Position*</label>
                    <select name="from_position"  required="required">
                        <option value="Full time body technician">Full time body technician</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="workExperience">Tell us about your relevant work experience*</label>
                    <textarea name="workExperience" required="required"/>
                </div>    
                <div>
                    <label htmlFor="message">Extra comments</label>
                    <textarea name="message"/>
                </div>
                <input type="submit" value="Submit" className="submitButton"/>
            </form>
        </StyledApply>
    )
}

export default Apply;