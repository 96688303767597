import styled from "styled-components";

// ᕙ(▀̿ĺ̯▀̿ ̿)ᕗ

export const StyledMobileHeader = styled.header `
    padding: 1% 5% 1% 5%;
    display: flex;
    box-shadow: 1px 0px 10px 1px grey;
    position: sticky;
    top: 0;
    z-index: 1;
    font-weight: 500;
    background-color: #f2f2f2;
    flex-direction: column;

    div {
        display: flex;
        justify-content: space-between;
    }

    #imgLink {
        margin: 1% 0 1% 0;
        width: 50%;
        font-size: 3.5vw;
        height: 4.7em;
    }

    .lsLogo {
        ${'' /* margin: 1% 0 1% 0;
        width: 50%;
        font-size: 3.5vw;
        height: 4.7em; */}
        width: 100%;
    }

    .phone {
        display: none;
        ${'' /* text-decoration: none;
        text-shadow: 1px grey;
        margin-right: 4%;
        margin-top: 5%; */}
    }

    nav a {
        text-decoration: none;
        transition: 0.3s;
        color: #1f1e1e;
        padding: 3% 30% 3% 30%;
    }

    ul { 
        padding: 0; 
        text-align: center;
    }

    li {
        display: inline-block;
        list-style: none;
        margin: 0 2% 0 2%;
        white-space: nowrap;

        font-size: 3vw;
        text-align: center;
        width: 100%;
        padding: 3% 0%;
        border-top: .5px solid gray;
        transition: 0.3s;
    }

    ${'' /* li:hover {
        background-color: red;
        box-shadow: 1px 1px 5px 1px grey;
    }

    li:hover a {
        font-weight: 650;
        color: #f2f2f2;
    } */}

    .hamburger {
        width: 7%;
        margin: 0 auto;
        margin-right: 3%;
    }

    .socalMediaIconContainer {
        display: none;
    }
`